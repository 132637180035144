.Links {
    position: fixed;
    left: 3.5%;
    bottom: 0;
    gap: 25px;
    display: flex;
    flex-direction: column;
    opacity: 0;
    z-index: 10;
    animation: fadeIn calc(var(--animation-time) * 4) 2.5s ease-in-out forwards;
}
.Links > a{
    cursor: pointer;
}
@media (max-width: 1200px) {
    .Links {
        display: none;
    }
}

.feather{
    transition: all 0.25s ease-in-out;
    color: var(--light-slate);
}
.feather:hover{
    transform: translate(0px,-5px);
    color: var(--green);
}

.Links::after{
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    background-color: var(--light-slate);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
