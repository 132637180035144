@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500&family=Roboto:wght@300&display=swap');

#content{
    display: flex;
    flex-direction: column;
    gap: 600px;
    margin: 300px auto;
    width: 100%;
}

#skip-content{
    display: flex;
    position: absolute;
    top: 15px;
    left: 10px;
    width: 150px;
    height: 50px;
    z-index: 10;
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: var(--green);
    color: black;
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
    transform: translate(-200px, 0px);
}

#skip-content:focus{
    transform: translate(0px, 0px);
}

.center{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    gap: 300px;
}

footer{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    color: var(--slate);
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 800;
    width: 100%;
}

footer > a{
    transition: all 0.25s ease-in-out;
}

footer:hover > a{
    color: var(--green);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 700px) {
    #content{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 600px;
        margin: 300px auto;
        width: 100%;
    }
}