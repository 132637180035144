.Work{
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 30px;
    margin: 0 25px;
}

.projects {
    display: flex;
    flex-direction: column;
}

.projects > div:nth-of-type(odd) > .project{
    display: flex;
    flex-direction: row;
}
.projects > div:nth-of-type(even) > .project{
    display: flex;
    flex-direction: row-reverse;
}
.projects > div:not(:last-child)  > .project {
    margin-bottom: 100px;
}

.project_picture{
    position: relative;
    background-size: cover;
    object-fit: cover;
    width: 550px;
    height: 400px;
    border-radius: 10px;
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    transition: all 0.25s ease-in-out;
}
.project_picture::after {
    content: "";
    background: var(--overlay);
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
}
.project_picture:hover::after {
    opacity: 0;
}

.projects > div:nth-of-type(even) > .project > .project-column{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 500px;
}
.projects > div:nth-of-type(odd) > .project > .project-column{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    text-align: end;
    width: 500px;
}

.project-column > h2 {
    color: var(--lightest-slate);
    line-height: normal;
    font-size: xx-large;
}

.project-column > p {
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    position: relative;
    z-index: 2;
    padding: 25px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--light-navy);
    color: var(--light-slate);
    line-height: 25px;
}

.project-flairs{
    display: flex;
    flex-direction: row;
    z-index: 10;
}

.project-flairs > li{
    color: var(--lightest-slate);
}

.project-links{
    display: flex;
    flex-direction: row;
    gap: 25px;
}

@media (max-width: 1200px) {
    .projects > div:nth-of-type(even) > .project > .project-column{
        width: 400px;
    }
    .projects > div:nth-of-type(odd) > .project > .project-column{
        width: 400px;
    }
}

@media (max-width: 1100px) {
    .projects > div:nth-of-type(even) > .project > .project-column{
        width: 300px;
    }
    .projects > div:nth-of-type(odd) > .project > .project-column{
        width: 300px;
    }
}

@media (max-width: 900px) {
    .project{
        margin: 0 auto;
    }
    .projects > div:nth-of-type(even), .projects > div:nth-of-type(odd){
        position: relative;
    }
    .projects > div:nth-of-type(even) > .project, .projects > div:nth-of-type(odd) > .project {
        flex-direction: row;
    }
    .projects > div:nth-of-type(even) > .project > .project-column, .projects > div:nth-of-type(odd) > .project > .project-column{
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: flex-start;
        justify-content: space-evenly;
        text-align: start;
        padding: 0 25px;
        width: 80%;
        height: 100%;
        margin: auto 0;
        top: 0;
        left: 0;
    }
    .project-column > p {
        box-shadow: none;
        position: relative;
        z-index: 0;
        padding: 0;
        width: auto;
        border-radius: 0;
        background-color: transparent;
    }
    .project_picture::after {
        background: var(--light-navy);
    }
    .project_picture:hover::after {}
    .projects > div:not(:last-child)  > .project  > .project-column {
        height: calc(100% - 100px);
        margin-bottom: 100px;
    }
}

@media (max-width: 600px) {
    .project_picture{
        width: 500px;
        height: 375px;
    }
}

@media (max-width: 550px) {
    .project_picture{
        width: 400px;
        height: 300px;
    }
    .projects > div  > .project  > .project-column * {
        padding: 0;
        margin: 0;
    }
    .projects > div  > .project  > .project-column > span {
        margin-bottom: 10px;
    }
    .Work {
        margin: 0 10px;
    }
    .Work > .projects {
        line-height: 10px;
    }
    .project-column > h2 {
        font-size: large;
    }
    .projects > div:not(:last-child)  > .project  > .project-column {
        height: calc(100% - 100px);
        width: 350px;
        margin-bottom: 100px;
    }
    .projects > div:last-child  > .project  > .project-column {
        height: 100%;
        width: 350px;
    }
}

@media (max-width: 425px) {
    .project_picture{
        width: 350px;
        height: 275px;
    }
    .Work > .projects {
        font-size: 12px;
    }
    .projects > div:not(:last-child)  > .project  > .project-column {
        height: calc(100% - 100px);
        width: 300px;
        margin-bottom: 100px;
    }
    .projects > div:last-child  > .project  > .project-column {
        height: 100%;
        width: 300px;
    }
}
