.Intro {
    margin-left: 25%;
    transform: translate(0px, -50px);
}

.my-name{
    color: var(--green);
    letter-spacing: 2px;
    font-weight: 600;
}

.heading{
    position: relative;
    left: -7px;
    margin: 20px 0;
    color: var(--light-slate);
    font-weight: 600;
    font-size: clamp(40px, 8vw, 80px);
    font-family: "Calibre","Inter","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
}

.kameo{
    color: var(--green);   
}

.intro-text{
    width: 600px;
    color: var(--slate);
    font-weight: 600;
    padding-right: 15px;
    letter-spacing: 2px;
    line-height: 30px;
    text-align: justify;
}

.anim6{
    opacity: 0;
    animation: fadeIn calc(var(--animation-time) * 3) 1.5s ease-in-out forwards, animToTop calc(var(--animation-time) * 3) 1.5s ease-in-out forwards;
}
.anim7{
    opacity: 0;
    animation: fadeIn calc(var(--animation-time) * 3) 1.75s ease-in-out forwards, animToTop calc(var(--animation-time) * 3) 1.75s ease-in-out forwards;
}
.anim8{
    opacity: 0;
    animation: fadeIn calc(var(--animation-time) * 3) 2s ease-in-out forwards, animToTop calc(var(--animation-time) * 3) 2s ease-in-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animToTop {
    from {
        transform: translate(0px, 25px);
    }
    to {
        transform: translate(0px, 0px);
    }
}

@media (max-width: 700px) {
    .intro-text {
        width: 90%;
    }
    .Intro {
        margin-left: 8%;
        transform: translate(0px, -50px);
    }
}