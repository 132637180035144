.Experience {
    display: flex;
    flex-direction: row;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 30px;
}

.exp-list{
    border-left: 2px solid var(--lightest-navy);
    height: fit-content;
    list-style: none;
    padding: 0;
}

.exp-list::before{
    position: absolute;
    left: 0px;
    top: var(--height, 16px);
    content: '';
    background-color: var(--green);
    width: 2px;
    height: 55px;  
    transition: all 0.25s ease-in-out;
}

.exp-item{
    color: var(--slate);
    height: 25px;
    width: 130px;
    padding: 15px;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

.Experience-container{
    height: 420px;
    width: 800px;
    margin: 0 25px;
}

@media (max-width: 900px) {
    .Experience-container{
        width: 90%;
        margin: 0 10px;
    }
}
