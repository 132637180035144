.About {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    color: var(--slate);
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 30px;
}

.About-container{
    margin: 0 50px;
}

.about-text{
    width: 600px;
    text-align: justify;
}

.about-grid{
    display: grid;
    grid-template-columns: auto auto auto;
    font-size: small;
    width: 50%;
    margin-left: 10px;
    column-gap: 50px;
}

.profile_picture{
    position: relative;
    background: url('/src/assets/images/photo_de_profil.jpg');
    background-size: cover;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
}

.profile_picture::after {
    content: "";
    background: var(--overlay);
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: all 0.4s ease-in-out;
}
.profile_picture:hover::after {
    opacity: 0;
}

.square{
    position: relative;
    width: 300px;
    height: 300px;
    border: 3px solid var(--green);
    pointer-events: none;
    border-radius: 10px;
    top: 20px;
    left: 20px;
    transition: all 0.25s ease-in-out;
    z-index: -1;
}

.profile_picture:hover > .square{
    top: 10px;
    left: 10px;
}

.kameo{
    color: var(--green);  
}

@media (max-width: 800px) {
    .about-text{
        width: 90%;
        text-align: justify;
    }
    .About-container{
        margin: 0 25px;
    }
}

@media (max-width: 450px) {
    .About-container{
        margin: 0 10px;
    }
}