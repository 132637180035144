@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500&family=Roboto:wght@300&display=swap');

html{
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}

body{
    font-family: 'Roboto', serif;
    overflow-y: hidden;
    overflow-x: hidden;
    counter-reset: section;
    margin: 0;
    padding: 0;
}

:root{
    --bg-loading: #020C1B;
    --bg-portfolio: #0A192F;
    --green: #64FFDA;
    --slate: #8892B0;
    --light-slate: #A8B2D1;
    --lightest-slate: #CCD6F6;
    --dark-slate: #495670;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2,12,27,0.7);
    --green-tint: rgba(100,255,218,0.1);
    --overlay: rgba(26, 255, 177, 0.5);
    --animation-time: 0.10s;
    --anchor-offset: -150px;
}

:focus-visible {
    outline: 2px dashed var(--green);
    outline-offset: 3px;
}

::-webkit-scrollbar {
    width: 12px;
}
  
::-webkit-scrollbar-track {
    background-color: var(--bg-portfolio);
}
  
::-webkit-scrollbar-thumb {
    background: var(--dark-slate);
    border: 3px solid var(--bg-portfolio);
    border-radius: 2em;
}

a {
    color: inherit;
    text-decoration: none;
}

.bg-dark{
    background-color: var(--bg-loading);
}

.bg-light{
    background-color: var(--bg-portfolio);
}

.App{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100vw;
    transition: all ease-in-out 0.5s;
}

.logo-container{
    margin: 0 auto;
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: left top;
    animation: shrink 0.15s 2s linear forwards;
}

.letter {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;  
    animation: fadeIn 2s 0s cubic-bezier(.58,-0.17,.39,1.08) forwards;
    color: var(--green);
    transform: translate(-50%, -50%);
    font-size: 550%;
}

.path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    overflow: visible;
    transform: translate(9%, 17%);   
    animation: dash 2s 0s cubic-bezier(.6,0,.13,.36) forwards;
}

.animated-anchor{
    position: relative;
    text-decoration: none;
    display: inline-block;
}
.animated-anchor::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 2px;
    left: -1px;
    background-color: var(--green);
    transform: scaleX(0);
    transform-origin: top right;
    transition: transform 0.3s ease;
    opacity: 0.5;
}
.animated-anchor:hover::before {
    transform: scaleX(1);
    transform-origin: top left;
}

.green{
    color: var(--green);   
}

.slate{
    color: var(--slate);
}

.light-slate{
    color: var(--light-slate);
}

.lightest-slate{
    color: var(--lightest-slate);
}

ul{
    list-style: none;
    padding-left: 0;
    
}
ul, li{
    list-style-position: outside;
    gap: 10px;
}
li.grid-item{
    padding-left: 5px;
}
.grid-item::marker{
    content: "▹";
    color: var(--green);
    line-height: 12px;   
}
  
@keyframes dash {
    from {
        stroke-dashoffset: 1000;
    }
    to {
        stroke-dashoffset: 500;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes shrink {
    from {
        transform: scale(1) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(0) translate(-50%, -50%);
        opacity: 0;
    }
}
