.Email {
    position: fixed;
    right: 3.5%;
    bottom: 0;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    writing-mode: vertical-rl;
    opacity: 0;
    z-index: 10;
    animation: fadeIn calc(var(--animation-time) * 4) 2.55s ease-in-out forwards;
}
@media (max-width: 1200px) {
    .Email {
        display: none;
    }
}

#mail{
    color: var(--light-slate);
    transition: all 0.25s ease-in-out;
    text-decoration: none;
    font-size: small; 
    letter-spacing: 2px;
    margin-bottom: 15px;
}

#mail:hover{
    transform: translate(0px,-5px);
    color: var(--green);
}

.Email::after{
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    background-color: var(--light-slate);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}