.SectionHeading{
    display: flex;
    flex-direction: row;
    gap: 25px;
    letter-spacing: 1.5px;
    margin-bottom: 50px;
    align-items: center;
}

.heading-number{
    color: var(--green);
}
.heading-number::before {
    counter-increment: section;
    content: counter(section) ".";
}

.heading-text{
    color: var(--lightest-slate);
}
.SectionHeading > a{
    position: relative;
    top: var(--anchor-offset);
    opacity: 0;
    font-size: 0;
}

#heading-line{
    height: 1px;
    flex-grow: 1;
    background-color: var(--lightest-navy);
}