.Navbar {
    display: flex;
    flex-direction: row;
    color: var(--light-slate);
    margin: 25px;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    letter-spacing: 2px;
    transition: all 0.25s ease-in-out;
}

.not-top{
    z-index: 1000;
    box-shadow: 0 2px 2px -2px rgb(25, 30, 54);
}

.not-top > .Navbar{
    padding: 25px;
}

.decoupled{
    top: -100px;
}

header{
    position: sticky;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100px;
    backdrop-filter: blur(10px);
    transition: all 0.25s ease-in-out;
}

.logo{
    position: relative;
    width: 50px;
    height: 50px;
    opacity: 0;
}

#anchor{
    margin-left: 2%;
}

.index{
    display: flex;
    flex-direction: row;
    gap: 25px;
    margin-right: 3%;
    font-size: small;
    align-items: center;
}

.index-el{
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    opacity: 0;
}
.index-el:hover{
    color: var(--green);
}

.number{
    color: var(--green);
    display: inline; 
}

.index > a > button{
    width: 100px;
    height: 40px;
    color: var(--green);
    border: 1px solid var(--green);
    border-radius: 10px;
    background-color: transparent;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    opacity: 0;
}
.index > a > button:hover{
    background-color: var(--green-tint);
}

.burger-menu {
    display: none;
}

.slide-right{
    left: 26vh;
    box-shadow: -2px 2px 2px -2px rgb(25, 30, 54);
    z-index: 1000;
}

.anim0{
    animation: fadeIn calc(var(--animation-time) * 3) 0.30s ease-in-out forwards, animToBottom calc(var(--animation-time) * 3) 0.30s ease-in-out forwards;
}
.anim1{
    animation: fadeIn calc(var(--animation-time) * 3) calc(var(--animation-time) * 0 + 0.50s) ease-in-out forwards, animToBottom calc(var(--animation-time) * 3) calc(var(--animation-time) * 0 + 0.50s) ease-in-out forwards;
}
.anim2{
    animation: fadeIn calc(var(--animation-time) * 3) calc(var(--animation-time) * 1 + 0.50s) ease-in-out forwards, animToBottom calc(var(--animation-time) * 3) calc(var(--animation-time) * 1 + 0.50s) ease-in-out forwards;
}
.anim3{
    animation: fadeIn calc(var(--animation-time) * 3) calc(var(--animation-time) * 2 + 0.50s) ease-in-out forwards, animToBottom calc(var(--animation-time) * 3) calc(var(--animation-time) * 2 + 0.50s) ease-in-out forwards;
}
.anim4{
    animation: fadeIn calc(var(--animation-time) * 3) calc(var(--animation-time) * 3 + 0.50s) ease-in-out forwards, animToBottom calc(var(--animation-time) * 3) calc(var(--animation-time) * 3 + 0.50s) ease-in-out forwards;
}
.anim5{
    animation: fadeIn calc(var(--animation-time) * 3) calc(var(--animation-time) * 4 + 0.50s) ease-in-out forwards, animToBottom calc(var(--animation-time) * 3) calc(var(--animation-time) * 4 + 0.50s) ease-in-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animToBottom {
    from {
        transform: translate(0px, -25px);
    }
    to {
        transform: translate(0px, 0px);
    }
}

@media (max-width: 700px) {
    .burger-menu {
        display: inline;
        position: absolute;
        top: 0;
        right: -10vh;
        margin: 20px;
    }
    .Navbar{
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10%;
        height: 100%;
        padding: 20px;
        margin: 10px;
    }
    #anchor{
        margin: 0 auto;
    }
    .index{
        flex-direction: column;
        align-items: center;
        gap: 10vh;
        width: 100%;
    }
    header{
        position: fixed;
        top: 0;
        left: 0;
        width: 26vh;
        height: 100vh;
        z-index: 1000;
        transform: translate(-26vh, 0);
        backdrop-filter: blur(10px);
    }
    .decoupled{
        top: 0;
    }
}